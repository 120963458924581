import React, { useState } from "react";
import Floatbutton from "../Functions/Floatbutton";
import { Helmet } from "react-helmet";

const Contactus = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://alpha.cndhearingsolution.co.nz/api/api/contact/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      console.log("Form submitted successfully:", response);
      alert("Form submitted successfully!");

      // Clear the form
      setFormData({
        name: "",
        mobile: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      console.error("There was an error submitting the form:", error);
      alert("There was an error submitting the form.");
    }
  };

  const handleCall = () => {
    window.location.href = "tel:+64212955629";
  };
  const handleMail = () => {
    window.location.href = "mailto:cndhearing@gamil.com";
  };
  return (
    <>
      <Helmet>
        <meta
          property="og:title"
          content="Ear Wax Removal By Ear Suction | CND Hearing Solutions"
        />
        <meta
          property="og:description"
          content="Contact Chris Joseph @ 021–2955629 to attain Ear Wax Removal By Ear Suction at highly reputed CND Hearing Solution."
        />
        <meta name="keywords" content="Ear Suction Specialist In Auckland" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Chris Joseph" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://cndhearingsolution.co.nz/" />
        <meta
          property="og:image"
          content="https://cndhearingsolution.co.nz/image.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Ear Wax Removal By Ear Suction | CND Hearing Solutions"
        />
        <meta
          name="twitter:description"
          content="CND Hearing Solution is now revolving as the best hearing clinic that provides ear wax removal by ear suction in and around auckland."
        />
        <meta
          name="twitter:image"
          content="https://cndhearingsolution.co.nz/image.jpg"
        />
      </Helmet>
      <section className="site-section bg-light mt-5" id="contact-section">
        <div className="container">
          <div className="row mb-5">
            <div className="col-12 text-center">
              <h2 className="text-black h1 site-section-heading">Contact Us</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 mb-5">
              <form
                action="#"
                className="p-5 bg-white"
                style={{
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
                onSubmit={handleSubmit}
              >
                <h2 className="h4 text-black mb-5">Contact Form</h2>
                <div className="row form-group">
                  <div className="col-md-6 mb-3 mb-md-0">
                    <label className="text-black" htmlFor="fname">
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="form-control"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-3 mb-md-0">
                    <label className="text-black" htmlFor="fname">
                      Mobile
                    </label>
                    <input
                      type="number"
                      name="mobile"
                      id="mobile"
                      className="form-control"
                      value={formData.mobile}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" htmlFor="email">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" htmlFor="subject">
                      Subject
                    </label>
                    <input
                      type="subject"
                      name="subject"
                      id="subject"
                      className="form-control"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row form-group">
                  <div className="col-md-12">
                    <label className="text-black" htmlFor="message">
                      Message
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      cols="30"
                      rows="7"
                      className="form-control"
                      placeholder="Write your notes or questions here..."
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="row form-group">
                  <div
                    className="col-md-12 "
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <input
                      type="submit"
                      value="Send Message"
                      className="btn home-contact btn-md text-white"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-5">
              <div
                className="p-4 mb-3 bg-white"
                style={{
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                }}
              >
                <p className="mb-0 font-weight-bold">Address</p>
                <p className="mb-4">
                  10,Tiverton Road, Avondale, Auckland- 0600.
                </p>
                <p className="mb-0 font-weight-bold">WORKING HOURS</p>
                <p className="mb-4">
                  WeekDays:-<strong> 6 AM to 8 PM</strong>
                  <br></br>WeekEnd:- <strong>10AM to 3PM</strong>
                </p>
                <p className="mb-0 font-weight-bold">Phone</p>
                <p
                  className="mb-4"
                  onClick={handleCall}
                  style={{ color: "#2c7bfe" }}
                >
                  +64212955629
                </p>
                <p className="mb-0 font-weight-bold">Mail</p>
                <p
                  className="mb-0"
                  onClick={handleMail}
                  style={{ color: "#2c7bfe" }}
                >
                  cndhearing@yahoo.com
                </p>
              </div>

              <div
                className="  mb-3"
                style={{
                  boxShadow:
                    " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  height: "400px",
                }}
              >
                <iframe
                  title="Google Map"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26137574.09639909!2d174.699992!3d-36.899394!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d0d4960ddf75181%3A0x678fe011982ce3d!2sEar%20Wax%20Removal%20in%20Auckland%20-%20CND%20Hearing%20Solutions%20Ltd!5e0!3m2!1sen!2sin!4v1720433348865!5m2!1sen!2sin"
                  width="100%"
                  height="400px"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Floatbutton />
    </>
  );
};

export default Contactus;
